import { Fragment } from "react";
import {
  Control,
  Controller,
  FieldPath,
  FieldValues,
  RegisterOptions,
} from "react-hook-form";

export type FormInputProps = {
  control: Control<any>;
  name: string;
  rules?: Omit<
    RegisterOptions<FieldValues, FieldPath<FieldValues>>,
    "valueAsNumber" | "valueAsDate" | "setValueAs"
  >;
  label: string;
  type?: string | undefined;
  icon?: any;
  inputProps?: any;
  inputGroup?: boolean;
  className: string;
  options?: Array<{ id: number | string; name: string }>;
};

function FormInput(props: FormInputProps) {
  const {
    control,
    name,
    rules,
    label,
    type,
    icon,
    inputProps,
    inputGroup = true,
    className,
    options = [],
  } = props;
  const {
    className: inputClass = "",
    showLabel = true,
    showEmptyOption = true,
    showSelectOption = false,
    ...inputFieldProps
  } = inputProps ?? {};

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState }) => (
        <div className={`${label ? '' : inputGroup ? 'input-group' : ''} ${className}`}>
          {icon !== undefined && (
            <span className="input-group-text">
              {icon}
            </span>
          )}
          {showLabel && label && type !== "checkbox" ? (
            <label
              className={`gray_light fz15 regular_font ${fieldState.error ? "text-danger" : ""
                }`}
            >
              {label}
            </label>
          ) : (
            <Fragment />
          )}
          {type === "select" ? (
            <select
              {...field}
              value={field.value}
              {...inputFieldProps}
              className={`${inputClass ? inputClass : ""
                } form-control form-select custom_form_control m-0 ${fieldState.error ? "is-invalid" : ""
                }`}
            >
              {showSelectOption ? (
                <option value={""}>Select {label}</option>
              ) : showEmptyOption ? (
                <option style={{ display: "none" }} />
              ) : null}
              {options?.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.name}
                </option>
              ))}
            </select>
          ) : type === "textarea" ? (
            <textarea
              {...field}
              {...inputFieldProps}
              className={`${inputClass ? inputClass : ""
                } form-control custom_form_control ${fieldState.error ? "is-invalid" : ""
                }`}
            ></textarea>
          ) : type === "checkbox" ? (
            <Fragment>
              <input
                {...field}
                type={type}
                {...inputFieldProps}
                className={`${inputClass ? inputClass : ""} mx-1 mt-3 ${fieldState.error ? "is-invalid" : ""
                  }`}
                name={label.replaceAll(" ", "_")}
              />
              <label
                htmlFor={label.replaceAll(" ", "_")}
                className={`gray_light fz15 regular_font ${fieldState.error ? "text-danger" : ""
                  }`}
              >
                {label}
              </label>
            </Fragment>
          ) : (
            <input
              {...field}
              type={type}
              {...inputFieldProps}
              className={`${inputClass ? inputClass : ""
                } form-control custom_form_control ${fieldState.error ? "is-invalid" : ""
                }`}
            />
          )}

          {fieldState.error ? (
            <small className="text-danger w-100" style={icon ? { paddingLeft: 40 } : {}}>{fieldState.error?.message}</small>
          ) : (
            <Fragment />
          )}
        </div>
      )}
    />
  );
}

export default FormInput;
