import React from "react";

const Dashboard = React.lazy(() => import("../views/dashboard/Dashboard"));
const Colors = React.lazy(() => import("../views/theme/colors/Colors"));
const Typography = React.lazy(
  () => import("../views/theme/typography/Typography")
);

// Base
const Accordion = React.lazy(() => import("../views/base/accordion/Accordion"));
const Breadcrumbs = React.lazy(
  () => import("../views/base/breadcrumbs/Breadcrumbs")
);
const Cards = React.lazy(() => import("../views/base/cards/Cards"));
const Carousels = React.lazy(() => import("../views/base/carousels/Carousels"));
const Collapses = React.lazy(() => import("../views/base/collapses/Collapses"));
const ListGroups = React.lazy(
  () => import("../views/base/list-groups/ListGroups")
);
const Navs = React.lazy(() => import("../views/base/navs/Navs"));
const Paginations = React.lazy(
  () => import("../views/base/paginations/Paginations")
);
const Placeholders = React.lazy(
  () => import("../views/base/placeholders/Placeholders")
);
const Popovers = React.lazy(() => import("../views/base/popovers/Popovers"));
const Progress = React.lazy(() => import("../views/base/progress/Progress"));
const Spinners = React.lazy(() => import("../views/base/spinners/Spinners"));
const Tabs = React.lazy(() => import("../views/base/tabs/Tabs"));
const Tables = React.lazy(() => import("../views/base/tables/Tables"));
const Tooltips = React.lazy(() => import("../views/base/tooltips/Tooltips"));

// Buttons
const Buttons = React.lazy(() => import("../views/buttons/buttons/Buttons"));
const ButtonGroups = React.lazy(
  () => import("../views/buttons/button-groups/ButtonGroups")
);
const Dropdowns = React.lazy(
  () => import("../views/buttons/dropdowns/Dropdowns")
);

//Forms
const ChecksRadios = React.lazy(
  () => import("../views/forms/checks-radios/ChecksRadios")
);
const FloatingLabels = React.lazy(
  () => import("../views/forms/floating-labels/FloatingLabels")
);
const FormControl = React.lazy(
  () => import("../views/forms/form-control/FormControl")
);
const InputGroup = React.lazy(
  () => import("../views/forms/input-group/InputGroup")
);
const Layout = React.lazy(() => import("../views/forms/layout/Layout"));
const Range = React.lazy(() => import("../views/forms/range/Range"));
const Select = React.lazy(() => import("../views/forms/select/Select"));
const Validation = React.lazy(
  () => import("../views/forms/validation/Validation")
);

const Charts = React.lazy(() => import("../views/charts/Charts"));

// Icons
const CoreUIIcons = React.lazy(
  () => import("../views/icons/coreui-icons/CoreUIIcons")
);
const Flags = React.lazy(() => import("../views/icons/flags/Flags"));
const Brands = React.lazy(() => import("../views/icons/brands/Brands"));

// Notifications
const Alerts = React.lazy(() => import("../views/notifications/alerts/Alerts"));
const Badges = React.lazy(() => import("../views/notifications/badges/Badges"));
const Modals = React.lazy(() => import("../views/notifications/modals/Modals"));
const Toasts = React.lazy(() => import("../views/notifications/toasts/Toasts"));

const Widgets = React.lazy(() => import("../views/widgets/Widgets"));

const ElectionTypes = React.lazy(
  () => import("../views/pages/election-types/ElectionTypes")
);
const Elections = React.lazy(
  () => import("../views/pages/elections/Elections")
);
const ElectionDetails = React.lazy(
  () => import("../views/pages/elections/ElectionDetails/index")
);
const CreateElection = React.lazy(
  () => import("../views/pages/elections/CreateElection/index")
);
const UpdateElection = React.lazy(
  () => import("../views/pages/elections/UpdateElection/index")
);
const ElectionConstits = React.lazy(
  () => import("../views/pages/election-constituencies/ElectionConstituencies")
);
const ElectionStates = React.lazy(
  () => import("../views/pages/election-states/ElectionStates")
);

const ElectionResults = React.lazy(
  () => import("../views/pages/election-results/ElectionResults")
);

const ResultStatus = React.lazy(
  () => import("../views/pages/result-status/ResultStatus")
);

const States = React.lazy(() => import("../views/pages/states/States"));
const Properties = React.lazy(
  () => import("../views/pages/properties/Properties")
);
const Regions = React.lazy(() => import("../views/pages/regions/Regions"));
const Tehsil = React.lazy(() => import("../views/pages/tehsil/Tehsil"));
const Countries = React.lazy(
  () => import("../views/pages/countries/Countries")
);
const Cities = React.lazy(() => import("../views/pages/cities/Cities"));
const Districts = React.lazy(
  () => import("../views/pages/districts/Districts")
);
const Constituencies = React.lazy(
  () => import("../views/pages/constituencies/Constituencies")
);
const ConstituenciesExtension = React.lazy(
  () => import("../views/pages/constituency-extension/ConstituenciesExtension")
);
const ConstituenciesTehsils = React.lazy(
  () => import("../views/pages/constituency-tehsils/ConstituenciesTehsils")
);
const Parties = React.lazy(() => import("../views/pages/parties/Parties"));
const CandidateResult = React.lazy(
  () => import("../views/pages/candidate-result/CandidateResult")
);
const Alliances = React.lazy(
  () => import("../views/pages/alliance-list/Alliance")
);
const AlliancesParties = React.lazy(
  () => import("../views/pages/alliance-parties/AllianceParties")
);
const CandCasteCategory = React.lazy(
  () => import("../views/pages/caste-category/CasteCategory")
);
const Candidates = React.lazy(
  () => import("../views/pages/candidates/Candidates")
);
const CandidateStatus = React.lazy(
  () => import("../views/pages/candidate-status/CandidateStatus")
);
const CandidateExtension = React.lazy(
  () => import("../views/pages/candidate-extension/CandidateExtension")
);

const ConstituencyExtensionDetail = React.lazy(
  () =>
    import(
      "../views/pages/constituency-extension-detail/ConstituencyExtensionDetail"
    )
);
const ResultEntry = React.lazy(
  () => import("../views/pages/result-entry/ResultEntry")
);

const RutUsers = React.lazy(() => import("../views/pages/rut-users/RutUsers"));

const routes = [
  { path: "/", exact: true, name: "Elections" },
  { path: "/dashboard", name: "Dashboard", element: Dashboard },
  /** Will Remove these routes later */
  { path: "/theme", name: "Theme", element: Colors, exact: true },
  { path: "/theme/colors", name: "Colors", element: Colors },
  { path: "/theme/typography", name: "Typography", element: Typography },
  { path: "/base", name: "Base", element: Cards, exact: true },
  { path: "/base/accordion", name: "Accordion", element: Accordion },
  { path: "/base/breadcrumbs", name: "Breadcrumbs", element: Breadcrumbs },
  { path: "/base/cards", name: "Cards", element: Cards },
  { path: "/base/carousels", name: "Carousel", element: Carousels },
  { path: "/base/collapses", name: "Collapse", element: Collapses },
  { path: "/base/list-groups", name: "List Groups", element: ListGroups },
  { path: "/base/navs", name: "Navs", element: Navs },
  { path: "/base/paginations", name: "Paginations", element: Paginations },
  { path: "/base/placeholders", name: "Placeholders", element: Placeholders },
  { path: "/base/popovers", name: "Popovers", element: Popovers },
  { path: "/base/progress", name: "Progress", element: Progress },
  { path: "/base/spinners", name: "Spinners", element: Spinners },
  { path: "/base/tabs", name: "Tabs", element: Tabs },
  { path: "/base/tables", name: "Tables", element: Tables },
  { path: "/base/tooltips", name: "Tooltips", element: Tooltips },
  { path: "/buttons", name: "Buttons", element: Buttons, exact: true },
  { path: "/buttons/buttons", name: "Buttons", element: Buttons },
  { path: "/buttons/dropdowns", name: "Dropdowns", element: Dropdowns },
  {
    path: "/buttons/button-groups",
    name: "Button Groups",
    element: ButtonGroups,
  },
  { path: "/charts", name: "Charts", element: Charts },
  { path: "/forms", name: "Forms", element: FormControl, exact: true },
  { path: "/forms/form-control", name: "Form Control", element: FormControl },
  { path: "/forms/select", name: "Select", element: Select },
  {
    path: "/forms/checks-radios",
    name: "Checks & Radios",
    element: ChecksRadios,
  },
  { path: "/forms/range", name: "Range", element: Range },
  { path: "/forms/input-group", name: "Input Group", element: InputGroup },
  {
    path: "/forms/floating-labels",
    name: "Floating Labels",
    element: FloatingLabels,
  },
  { path: "/forms/layout", name: "Layout", element: Layout },
  { path: "/forms/validation", name: "Validation", element: Validation },
  { path: "/icons", exact: true, name: "Icons", element: CoreUIIcons },
  { path: "/icons/coreui-icons", name: "CoreUI Icons", element: CoreUIIcons },
  { path: "/icons/flags", name: "Flags", element: Flags },
  { path: "/icons/brands", name: "Brands", element: Brands },
  {
    path: "/notifications",
    name: "Notifications",
    element: Alerts,
    exact: true,
  },
  { path: "/notifications/alerts", name: "Alerts", element: Alerts },
  { path: "/notifications/badges", name: "Badges", element: Badges },
  { path: "/notifications/modals", name: "Modals", element: Modals },
  { path: "/notifications/toasts", name: "Toasts", element: Toasts },
  { path: "/widgets", name: "Widgets", element: Widgets },

  /** Election Portal URLs */
  {
    path: "/create-election",
    name: "Create Election",
    element: CreateElection,
  },
  {
    path: "/update-election",
    name: "Update Election",
    element: UpdateElection,
  },
  { path: "/elections", name: "Elections", element: Elections },
  { path: "/election/types", name: "Election Types", element: ElectionTypes },
  {
    path: "/election/election-constituencies",
    name: "Election Constituencies",
    element: ElectionConstits,
  },
  {
    path: "/election/election-state",
    name: "Election States",
    element: ElectionStates,
  },
  {
    path: "/election/election-results",
    name: "Election Results",
    element: ElectionResults,
  },

  {
    path: "/election/result-status",
    name: "Result Status",
    element: ResultStatus,
  },

  {
    path: "/election/candidate-extension",
    name: "Candidate Extension",
    element: CandidateExtension,
  },

  { path: "/election/regions", name: "Regions", element: Regions },
  { path: "/election/countries", name: "Countries", element: Countries },
  { path: "/election/states", name: "States", element: States },
  { path: "/election/cities", name: "Cities", element: Cities },
  { path: "/election/districts", name: "Districts", element: Districts },
  {
    path: "/election/properties",
    name: "Properties (Channels)",
    element: Properties,
  },
  {
    path: "/election/constituencies",
    name: "Constituencies",
    element: Constituencies,
  },
  { path: "/election/tehsils", name: "Tehsils", element: Tehsil },
  {
    path: "/election/constituencies/extensions",
    name: "Extensions",
    element: ConstituenciesExtension,
  },
  {
    path: "election/constituencies/extensions/detail",
    name: "ConstituencyExtensions",
    element: ConstituencyExtensionDetail,
  },
  {
    path: "election/constituencies/extensions/:constituencyId",
    name: "ConstituencyExtensions",
    element: ConstituencyExtensionDetail,
  },
  {
    path: "/election/constituencies/constituencies/tehsils",
    name: "Tehsils",
    element: ConstituenciesTehsils,
  },
  { path: "/election/parties", name: "Parties", element: Parties },
  {
    path: "/election/candidate-result",
    name: "Candidate Result",
    element: CandidateResult,
  },

  { path: "/election/alliances", name: "Alliances", element: Alliances },
  {
    path: "/election/alliances-parties",
    name: "Alliances Parties",
    element: AlliancesParties,
  },

  {
    path: "/election/caste-category",
    name: "Candidate Caste Category",
    element: CandCasteCategory,
  },
  { path: "/election/candidates", name: "Candidates", element: Candidates },
  {
    path: "/election/candidate-status",
    name: "Candidate Status",
    element: CandidateStatus,
  },
  {
    path: "/election/candidate-extension",
    name: "Candidate Extension",
    element: CandidateExtension,
  },
  {
    path: "/election/result-entry",
    name: "Result Entry ",
    element: ResultEntry,
  },
  {
    path: "/election/:electionId",
    name: "Election Details",
    element: ElectionDetails,
  },
  {
    path: "/election/users",
    name: "Rut Users",
    element: RutUsers,
  },
];

export default routes;
