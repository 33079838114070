import React, { Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { CContainer } from "@coreui/react";

// routes config
import routes from "./routes";
import useAuthValue from "src/modules/authModule/Hooks/useAuthValue";

const AppRouter = () => {
  const {permissions} = useAuthValue();
  const defaultTo: string = permissions.includes('*') ? '/elections' : (permissions.find(p => p.includes('election')) || '/')
  return (
    <CContainer className="px-4" lg>
      <Suspense>
        <Routes>
          <Route path="/" element={<Navigate to={defaultTo} replace />} />

          {routes.filter(({path}) => permissions.includes('*') || permissions.includes(path)).map((route, idx) => {
            return (
              route.element && (
                <Route
                  key={idx}
                  path={route.path}
                  id={route.name}
                  element={<route.element />}
                />
              )
            );
          })}
        </Routes>
      </Suspense>
    </CContainer>
  );
};

export default React.memo(AppRouter);
